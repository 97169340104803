<script>
	import Portal from 'svelte-portal';
	import { onMount } from 'svelte';

	export let lang;

	let en = lang == 'en'

	let Strings =
	lang == 'en'
		? {
			list: "List",
			myList: 'My List',
			listContent: 'Start creating lists for all your upcoming projects.',
			logIn: 'Sign in or Sign up',
			noThanks: 'No Thanks'
		}
		: {
			list: "Liste",
			myList: 'Mes liste',
			listContent: 'Pour créer des listes pour tous vos projets à venir.',
			logIn: 'Connectez-vous ou Créer un compte',
			noThanks: 'Non merci'
		};
	let account = false;
	let wishlistArr = []

	let openModal = false;
	// onMount(() => {
	// 	let event = new CustomEvent('fetchwishlist', {
	// 		detail: {
	// 		lang: document.querySelector('html').lang
	// 		}
	// 	});
	// 	window.dispatchEvent(event);
	// });
	// console.log(`hello from ${lang} wishlist`)

	function modalToggle() {
		openModal = !openModal;
	}

	function returnUrlHandler() {
		if (window.location.search.toLowerCase().includes('returnurl')) {
			let queryString = window.location.search.toLowerCase();
			let params = new URLSearchParams(queryString);
			return params.get('returnurl');
		}

		return window.location.href;
	}

	window.addEventListener('hhWishlistAdd', async function (e) {
		try {
			let userData =
			document.cookie.match(new RegExp('(^| )user=([^;]+)'))?.pop() || false;
			// console.log('userData', userData);

			if (!userData) return false;
			userData = JSON.parse(decodeURIComponent(userData)) || false;

			if (userData.isAnonymous === 'true' || userData.isAnonymous === true) {
				openModal = true;
				return 0;
			}

			let payload = {
				accountId: userData.accountId,
				lang: e.detail.lang,
				productData: e.detail.productData
			};
			let response = await fetch(`/_api/${e.detail.lang}/wishlist`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			});
			response = await response.json();
			const wishlistArr = JSON.parse(localStorage.getItem('wishlistProducts'));
			if (!wishlistArr || !wishlistArr.length) {
				localStorage.setItem(
					'wishlistProducts',
					JSON.stringify([response?.product?.productCode])
				);
				window.dispatchEvent(new Event('hhWishlistAdded'));
				return 0;
			}

			if (!wishlistArr.includes(response?.product?.productCode)) {
				localStorage.setItem(
					'wishlistProducts',
					JSON.stringify([...wishlistArr, response?.product?.productCode])
				);
				window.dispatchEvent(new Event('hhWishlistAdded'));
			}
			return 1;
		} catch (error) {
			console.error('Add to Wishlist error', error);
		}
	});

	function getCookie(name) {
		var cookieArr = document.cookie.split(';');
		for (var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split('=');
			if (name == cookiePair[0].trim())
			return decodeURIComponent(cookiePair[1]);
		}
		return null;
	}

	window.addEventListener('wishlistadded', async function (e) {
	//console.log('e.detail', e.detail)
		try {
			let payload = {
				accountId: e.detail.accountId,
				lang: e.detail.lang,
				productData: e.detail.productData
			};
			let response = await fetch(`/_api/${e.detail.lang}/wishlist`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			});
			response = await response.json();
			const wishlistArr = JSON.parse(localStorage.getItem('wishlistProducts'));
			if (!wishlistArr || !wishlistArr.length) {
				localStorage.setItem(
					'wishlistProducts',
					JSON.stringify([response?.product?.productCode])
				);
				return 0;
			}

			if (!wishlistArr.includes(response?.product?.productCode)) {
				localStorage.setItem(
					'wishlistProducts',
					JSON.stringify([...wishlistArr, response?.product?.productCode])
				);
			}
			return 1;
		} catch (error) {
			console.error('Add to Wishlist error', error);
		}
	});

	/** Kibo Wishlist Action(add): Things to perform on Kibo's end when Kibo's Add To Wishlist is done */
	window.addEventListener('wishlistaddedkibo', e => {
	try {
		const wishlistArr = JSON.parse(localStorage.getItem('wishlistProducts'));
		if (!wishlistArr || !wishlistArr.length) {
			localStorage.setItem(
				'wishlistProducts',
				JSON.stringify([e.detail?.productCode])
			);
			return 0;
		}
		if (!wishlistArr.includes(e.detail?.productCode)) {
			localStorage.setItem(
				'wishlistProducts',
				JSON.stringify([...wishlistArr, e.detail?.productCode])
			);
		}
		return 1;
	} catch (error) {
		console.error('Kibo wishlist array set error', error);
	}
	});

	/** Kibo Wishlist Action(remove): Code to run on Kibo's end when item removed from List  */
	window.addEventListener('wishlishremovekibo', e => {
		try {
			const wishlistArr = JSON.parse(localStorage.getItem('wishlistProducts'));
			let filteredWishlist = wishlistArr.filter(
				ele => ele != e.detail.productCode
			);
			localStorage.setItem('wishlistProducts',JSON.stringify(filteredWishlist));
		} catch (error) {
			console.warn("Error after Kibo's remove Item from wishlist", error);
		}
	});

	/** If User is logged In need to fetch Wishlist and add products to localStorage under wishlistProducts */
	async function fetchWishlist(e) {
		try {
			let userData = getCookie('user');
			if (!userData) return 0;

			userData = JSON.parse(userData);
			if (userData.isAnonymous === 'true' || userData.isAnonymous === true) return 0;

			let response = await fetch(
				`/_api/${e.detail.lang}/${userData.accountId}/wishlist`
			);
			response = await response.json();

			wishlistArr = response.items.map(item => item.product.productCode);
			wishlistArr && wishlistArr.length
			? window.localStorage.setItem(
				'wishlistProducts',
				JSON.stringify(wishlistArr)
				)
			: '';
		} catch (error) {
			console.error('Get Wishlist error', error);
		}
	}

	/**WishlistItemDelete Event*/
	async function wishlistItemRemove(e) {
		try {

			let response = await fetch(
				`/_api/${e.detail.lang}/user/wishlist/item/delete?wishlistId=${e.detail.wishlistId}&wishlistItemId=${e.detail.wishlistItemId}`
			);
			response = await response.json();
			wishlistArr = JSON.parse(localStorage.getItem('wishlistProducts'));
			wishlistArr = wishlistArr.filter(
				ele => ele != e.detail.productCode
			);
			localStorage.setItem('wishlistProducts', JSON.stringify(wishlistArr));
		} catch (error) {
			console.error('Remove from Wishlist error', error);
		}
	}


	async function main() {
		try {
			let userData = getCookie('user');
			if (!userData) return 0;
	
			userData = JSON.parse(userData);
			if (userData.isAnonymous === 'true' || userData.isAnonymous === true) return 0;
	
			account = true;
			let response = await fetch(`/_api/${lang}/${userData.accountId}/wishlist`)
			if(!response.status == 200) throw 'Error Fetching Wishlist'
			response = await response.json();
			wishlistArr = response.items.map(item => item.product.productCode);
			wishlistArr && wishlistArr.length
				? window.localStorage.setItem('wishlistProducts', JSON.stringify(wishlistArr))
				: '';
			
		} catch (error) {
			console.error('Get Wishlist error', error);
			
		}
	}
	main();
	window.addEventListener('fetchwishlist', fetchWishlist, false);
	window.addEventListener('wishlistItemRemove', wishlistItemRemove, false);
</script>

{#if account}
	 <!-- content here -->
	 <a href={`/${lang}/landing/spa-my-account#/wishlist`}>
		 <img src="https://homehardware.sirv.com/hh/svg/icon.fav.svg" alt="{ en ? 'Wish List Icon' : 'Icône de liste de souhaits' }" class="icon" width="0" height="0" />
		 <div class="wishlist-count" data-lang="{lang}">{wishlistArr.length}</div>
		 {Strings.list}
	 </a>
{:else}
	<!-- else content here -->
	<a href={`/${lang}/landing/no-wishlist`}>
		<img src="https://homehardware.sirv.com/hh/svg/icon.fav.svg" alt="{ en ? 'Wish List Icon' : 'Icône de liste de souhaits' }" class="icon" width="0" height="0" />
		<div class="wishlist-count" data-lang="{lang}">{ 0}</div>
	{Strings.list}
</a>
{/if}



{#if openModal}
  <Portal target="hh-modal">
    <!-- <img src="https://homehardware.sirv.com/hh/svg/icon.close-black.svg" class="modal-closeBtn" alt="no img" width="15" height="15" style="display:flex; justify-content: flex-end;" on:click={modalToggle}> -->
    <h3 class="Center modalHeading">
      <img
        id="btn-wishlist"
        alt="icon heart"
        src="https://homehardware.sirv.com/hh/icons/icon-heart.blackoutline.svg"
      />{Strings.myList}
    </h3>
    <p class="Center">{Strings.listContent}</p>
    <div class="buttonGroup">
      <a
        class="Button"
        href={`/${lang}/user/login?returnurl=${returnUrlHandler()}`}
        >{Strings.logIn}</a
      >
      <button class="Button ButtonSecondary" on:click={modalToggle}
        >{Strings.noThanks}</button
      >
    </div>
    <!-- <p class="Center">
				{@html lang === 'en'? `<a href="/${lang}/user/login?returnurl=${returnUrlHandler()}#addWishlist"><strong>Log in</strong></a> or <a href="/${lang}/user/signup?returnUrl=${returnUrlHandler()}#addWishlist"><strong>Create an Account</strong></a><br> to create lists for all your upcoming projects.` : `<a href="/${lang}/user/login?returnurl=${returnUrlHandler()}#addWishlist"><strong>Connectez-vous</strong></a> or <a href="/${lang}/user/signup?returnUrl=${returnUrlHandler()}#addWishlist"><strong>Créer un compte</strong></a><br> pour créer des listes pour tous vos projets à venir.`}
			</p> -->
  </Portal>
{/if}

<style>
	:global(#hh-mfe-header-wishlist) {
		position: relative;
	}
    .wishlist-count {
        color: #000;
        display: inline-block;
        border-radius: 50%;
        background: #fff;
        text-align: center;
        position: absolute;
        right: -14%;
        top: -4%;
        z-index: 1;
        font-size: 12px;
        width: 13.29px;
        height: 13.29px;
        line-height: 15px;
    }
    .wishlist-count[data-lang="fr"] {
        right: 5px;
    }	
	.modalHeading {
		border-bottom: 1px solid var(--hh-grey);
		padding-bottom: 0.8em;
	}
	.modalHeading > img:first-child {
		margin-top: 2px;
		width: 20px;
		height: 17.53px;
		cursor: pointer;
		margin-right: 8px;
	}
	.buttonGroup {
		margin-top: 1em;
	}
	.buttonGroup a {
		text-decoration: none;
	}

	@media (max-width: 600px) {
        .wishlist-count,
        .wishlist-count[data-lang="fr"] {
            top: -26%;
            right: -50%;
            width: 11px;
            height: 11px;
            font-size: 10px;
            line-height: 13px;
        }
    }
    @media (min-width:600px) and (max-width:800px) {
        .wishlist-count,
        .wishlist-count[data-lang="fr"] {
            right: 0;
            top: -5px;
            width: 11px;
            height: 11px;
            font-size: 10px;
            line-height: 13px;
        }
    }
</style>
