import HeaderWishlist from './HeaderWishlist.svelte'

if (!document.getElementById('hh-mfe-header-wishlist')) {
	let newMount = document.createElement('div')
	newMount.setAttribute('id', 'hh-mfe-header-wishlist')
	document.body.appendChild(newMount)
}

const mount	= document.getElementById('hh-mfe-header-wishlist')
const lang	= document.querySelector('html').lang || 'en'

if (mount) {
	mount.innerHTML = null
	new HeaderWishlist({
		target: mount,
		props: {
			lang: lang
		}
	})
}